import { Map } from '@/models/Map';

export const MapRequest = (argMap) => {
  const map = argMap.copy()

  if (!map.id) {
    delete map.id
  }

  return map
}

export const MapResponse = (map) => {
  return new Map(map)
}

export const MapsResponse = (maps) => {
  return maps.map((a) => MapResponse(a))
}